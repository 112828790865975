import React from "react";
import { Link } from 'react-scroll';
import portfolioPicture from "./portfolio_picture.png";
import './App.css'



const App = () => {

  return (
    <div>
      <NavBarfunction></NavBarfunction>
      <IntroFunction></IntroFunction>
      <WorkFunction></WorkFunction>
      <ContactFunction></ContactFunction>
    </div>  
  );  
}

const NavBarfunction = () => {

  return (
    <div className="NavigationBar">
      <button className="button homeButton">Savan Patel</button>

      <Link activeClass="active" to="IntroSection" smooth={true} duration={500}>
        <button className="button aboutButton">About</button>
      </Link>

      <Link activeClass="active" to="WorkSection" smooth={true} duration={500}>
        <button className="button workButton">Work</button>
      </Link>
      
      <Link activeClass="active" to="ContactSection" smooth={true} duration={500}>
        <button className="button contactButton">Contact</button>
      </Link>
    </div>
  );
};

const IntroFunction = () => {
  return (
    <div className="IntroSection">
      <div className="portfolioPicture">
        <img src={portfolioPicture} width="29%" max-width="150px" max-height="150px" alt="profile"></img>
      </div>
      <div className="vl-1"></div>
      <div className="ValueText">
        <h2>
          Hello, I'am Savan
        </h2>
      </div>
    </div>
  );
};

const WorkFunction =() => {
  return (
    <div className="WorkSection" >
      <div className="KeyText-2">
        <h2>Work</h2>
      </div>
      <div className="vl-2"></div>
      <div className="ValueText-2">
        <h2>Software Engineer @ Splunk</h2>
      </div>
    </div>
  );
};

const ContactFunction =() => {
  return (
    <div className="ContactSection" >
      <div className="KeyText-3">
        <h2>EMail</h2>
      </div>
      <div className="vl-3"></div>
      <div className="ValueText-3">
        <h2>savanrpatel@outlook.com</h2>
      </div>
    </div>
  );
};


export default App;